var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","to":{ name: 'new_expense_type' }}},[_vm._v(" New ")]),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Expenses List"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"items-per-page":20,"headers":[
        { text: 'Id', value: 'id' },
        { text: this.translate('Name'), value: 'name' },
        { text: this.translate('Actions'), value: 'action', width: '10%' } ],"items":JSON.parse(JSON.stringify(this.products))},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-warning-base)"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.editing_item = JSON.parse(JSON.stringify(item));
                item.dialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true),model:{value:(item.dialog),callback:function ($$v) {_vm.$set(item, "dialog", $$v)},expression:"item.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"}),_c('v-card-text',[_c('v-text-field',{staticClass:"shrink",attrs:{"outlined":"","dense":"","label":_vm.translate('Expense Name'),"hide-details":""},model:{value:(_vm.editing_item.name),callback:function ($$v) {_vm.$set(_vm.editing_item, "name", $$v)},expression:"editing_item.name"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isItemEdited || _vm.loading,"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.editData()}}},[_vm._v(" "+_vm._s(_vm.translate("SAVE"))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-error-base)"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.editing_item = JSON.parse(JSON.stringify(item));
                item.delete_dialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(item.delete_dialog),callback:function ($$v) {_vm.$set(item, "delete_dialog", $$v)},expression:"item.delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Deleting "+_vm._s(item.name))]),_c('v-card-text',[_vm._v(_vm._s(_vm.translate("Are you sure want to delete this item"))+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){item.delete_dialog = false}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(_vm._s(_vm.translate("OK")))])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }