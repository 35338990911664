<template>
  <div>
    <v-btn class="mt-6" color="primary" :to="{ name: 'new_expense_type' }"> New </v-btn>
    <v-card>
      <v-card-title>
        {{ translate("Expenses List") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :search="search"
        :items-per-page="20"
        :headers="[
          { text: 'Id', value: 'id' },
          { text: this.translate('Name'), value: 'name' },
          { text: this.translate('Actions'), value: 'action', width: '10%' },
        ]"
        :items="JSON.parse(JSON.stringify(this.products))"
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-dialog v-model="item.dialog" :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="color: var(--v-warning-base)"
                @click.stop="
                  editing_item = JSON.parse(JSON.stringify(item));
                  item.dialog = true;
                "
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5"> </v-card-title>

              <v-card-text>
                <v-text-field
                  v-model="editing_item.name"
                  outlined
                  dense
                  class="shrink"
                  :label="translate('Expense Name')"
                  hide-details
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="isItemEdited || loading"
                  color="primary"
                  @click="editData()"
                  :loading="loading"
                >
                  {{ translate("SAVE") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="item.delete_dialog" :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="
                  editing_item = JSON.parse(JSON.stringify(item));
                  item.delete_dialog = true;
                "
                small
                style="color: var(--v-error-base)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5">Deleting {{ item.name }}</v-card-title>
              <v-card-text
                >{{ translate("Are you sure want to delete this item") }}?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="item.delete_dialog = false">{{
                  translate("Cancel")
                }}</v-btn>
                <v-btn color="primary" @click="deleteItem()">{{ translate("OK") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { mdiClipboardPlusOutline, mdiDotsVertical } from "@mdi/js";
import {
  editMeasurement,
  getExpenseTypes,
  getMeasurements,
  editExpenseType,
  deleteExpenseType,
} from "@/ApiManager";

export default {
  computed: {
    isItemEdited() {
      var original_item = this.products.find((a) => {
        return a.id === this.editing_item.id;
      });

      if (JSON.stringify(this.editing_item) == JSON.stringify(original_item)) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      search: "",
      loading: false,

      editing_item: {},
      products: [],
    };
  },
  setup() {
    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiDotsVertical,
      },
    };
  },
  created() {
    this.$store.state.loading = true;
    getExpenseTypes().then((response) => {
      try {
        for (var i = 0; i < response.length; i++) {
          var product = {
            dialog: false,
            delete_dialog: false,
            id: parseInt(response[i]["id"]),
            name: response[i]["name"],
          };
          this.products.push(product);
        }
      } catch (Exception) {}
      this.$store.state.loading = false;
    });
  },
  methods: {
    deleteItem() {
      this.loading = true;
      deleteExpenseType(this.editing_item.id).then((response) => {
        var old_item = this.products.find((a) => {
          return a.id === this.editing_item.id;
        });
        this.loading = false;
        this.products = this.products.filter(function (itemm) {
          return itemm !== old_item;
        });
      });
    },
    editData() {
      this.loading = true;

      editExpenseType(this.editing_item.id, this.editing_item.name).then((response) => {
        this.loading = false;
        this.products.find((a) => {
          return a.id === this.editing_item.id;
        }).name = this.editing_item.name;
      });
    },
  },
};
</script>
